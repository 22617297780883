<template>
<div class="common">
    <div class="commonTop flex flex-ai-c flex-jc-sb" :style="{'left':isCollapse?'64px':'150px'}">
        <div class="name flex flex-ai-c">天缘汽配数字化经营管理系统</div>
        <div class="userbox flex flex-ai-c flex-jc-sb">
            <el-dropdown trigger="click" class="msg" style="cursor: pointer;margin-right:20px">
                <span class="el-dropdown-link">
                    <i class="el-icon-s-help"></i>
                    <span style="margin-left:5px">业务端</span>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <img :src="require('../assets/yw.png')" style="width: 170px;padding: 10px;box-sizing: border-box;">
                </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown trigger="click" class="msg" style="cursor: pointer;margin-right:20px">
                <span class="el-dropdown-link">
                    <i class="el-icon-s-help"></i>
                    <span style="margin-left:5px">汽修厂端</span>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <img :src="require('../assets/qx.png')" style="width: 170px;padding: 10px;box-sizing: border-box;">
                </el-dropdown-menu>
            </el-dropdown>
            <div class="msg flex flex-ai-c" @click="goMsg()" v-if="$root.count">
                <p>消息通知</p>
                <el-badge :value="$root.count.count==0?'':$root.count.count" class="item">
                    <img src="../assets/laba2.png" alt="">
                </el-badge>
            </div>
            <div class="user">
                <el-dropdown>
                    <span class="el-dropdown-link flex flex-ai-c">
                        {{$root.userInfo.name}}<i class="el-icon-arrow-down" style="margin-left:5px"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <!-- <el-dropdown-item icon="el-icon-unlock" @click.native="repwd()">修改密码</el-dropdown-item> -->
                        <el-dropdown-item icon="el-icon-s-unfold" @click.native="logOut()">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>

    <div class="commonLeft" :style="{'width':isCollapse?'64px':'150px'}">
        <div class="nav">
            <el-aside>
                <div class="headImg">
                    <img :style="{'width':isCollapse?'42px':'60px'}" src="../assets/avatars.jpg" alt="">
                    <!-- <p v-show="!isCollapse">{{$root.userInfo.name}}</p> -->
                </div>
                <el-menu background-color="#212123" text-color="#CDCDD6" :unique-opened="true" :default-active="$route.path" active-text-color="#fff" :router="true" :collapse="isCollapse" style="height:calc( 100% - 145px )">
                    <template v-for="nav in navList">

                        <el-menu-item :index="nav.path" :key="nav.name" v-if="!nav.sub">
                            <i :class="nav.icon"></i>
                            <span slot="title">
                                {{nav.name}}
                            </span>
                        </el-menu-item>
                        <!-- 二级列表 -->
                        <el-submenu :index="nav.path" :key="nav.name" v-if="nav.sub">
                            <template slot="title">
                                <i :class="nav.icon" v-if="nav.icon"></i>
                                <span> {{nav.name}} </span>
                            </template>
                            <template v-for="item in nav.sub">
                                <el-menu-item :index="item.path" :key="item.name">
                                    <i :class="item.icon" v-if="item.icon"></i>
                                    <el-badge :value="item.count==0?'':item.count">
                                        <span style="font-size:13px;">{{item.name}}</span>
                                    </el-badge>
                                </el-menu-item>
                            </template>
                        </el-submenu>

                    </template>
                    <p style="height:50px;background:#212123"></p>
                </el-menu>

                <div class="menuBtnbox" :style="{'width':isCollapse?'64px':'150px'}">
                    <i class="el-icon-s-operation menuBtn" @click="handleCollapse()"></i>
                </div>
            </el-aside>
        </div>
    </div>
    <div class="commonRight" :style="{'left':isCollapse?'64px':'150px'}">
        <router-view :isCollapse="isCollapse" />
    </div>

    <el-dialog title="修改密码" :visible.sync="repwdVisible" :modal-append-to-body="false" :close-on-click-modal="false" width="380px" center>
        <el-form ref="formAuth" :model="pwdForm" :rules="formRules" :label-width="formLabelWidth">
            <el-form-item label="原密码" prop="old_password">
                <el-input size="medium" v-model="pwdForm.old_password"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="new_password">
                <el-input size="medium" show-password v-model="pwdForm.new_password"></el-input>
            </el-form-item>
            <!-- <el-form-item label="确认密码" prop="verifyPassword" style="margin-bottom:0">
                    <el-input size="medium" show-password v-model="pwdForm.verifyPassword"></el-input>
                </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" type="primary" @click="repwdEnter" style="width:100%">确 定</el-button>
        </div>
    </el-dialog>

    <!-- 图片放大查看 -->
    <el-dialog title="图片查看" :visible.sync="dialogVisible" :modal-append-to-body="false" :close-on-click-modal="false" width="350px">
        <img height="100%" :src="dialogImageUrl" alt="" style="display:block;margin:0 auto">
    </el-dialog>
</div>
</template>

<script>
import axios from 'axios';
export default {
    data: function() {
        var oldPassword = (rule, value, callback) => {
            if(!value) {
                return callback(new Error('请输入原密码'));
            } else {
                callback();
            }
        };
        var newPassword = (rule, value, callback) => {
            if(!value) {
                callback(new Error('请输入新密码'));
            } else {
                if(this.pwdForm.newPassword !== '') {
                    this.$refs.formAuth.validateField('verifyPassword');
                }
                callback();
            }
        };
        var verifyPassword = (rule, value, callback) => {
            if(!value) {
                callback(new Error('请再次输入密码'));
            } else if(value !== this.pwdForm.newPassword) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            pwdForm: {
                id: ''
            },
            count: '',
            formRules: {
                old_password: [{
                    validator: oldPassword,
                    trigger: 'blur'
                }],
                new_password: [{
                    validator: newPassword,
                    trigger: 'blur'
                }],
                verifyPassword: [{
                    validator: verifyPassword,
                    trigger: 'blur'
                }],
            },
            formLabelWidth: '80px', //弹出form label 宽
            repwdVisible: false,
            defaultActive: this.$route.path,
            isCollapse: false,
            userInfo: {},
            dialogImageUrl: require('../assets/er.png'), //照片
            dialogVisible: false, //照片
            navList: [{
                name: '统计概况',
                path: '/overview',
                icon: 'el-icon-s-finance',
            }, {
                name: '商品管理',
                path: '/goodsList',
                icon: 'el-icon-s-goods',
                sub: [{
                    name: '商品列表',
                    path: '/goodsList',
                }, {
                    name: '商品统计',
                    path: '/goodsDetail',
                }, {
                    name: '商品分类',
                    path: '/goodsType',
                }, {
                    name: '商品品牌',
                    path: '/goodsBrand',
                }]
            }, {
                name: '站点管理',
                path: '/bureau',
                icon: 'el-icon-takeaway-box',
            }, {
                name: '客户管理',
                path: '/customer',
                icon: 'el-icon-s-custom',
            }, {
                name: '客情服务',
                path: '/CustomerServiceIndex',
                icon: 'el-icon-s-order',
                sub: [{
                    name: '拜访记录',
                    path: '/VisitRecordList',
                }, {
                    name: '打卡记录',
                    path: '/ClockinRecordsList',
                }, {
                    name: '拜访统计',
                    path: '/VisitStatistics',
                }]
            }, {
                name: '订单管理',
                path: '/orderIndex',
                icon: 'el-icon-s-order',
                sub: [{
                    name: '订单列表',
                    path: '/orderIndex',
                }, {
                    name: '退单列表',
                    path: '/chargeback',
                }]
            }, {
                name: '保养管理',
                path: '/maintain',
                icon: 'el-icon-s-open',
                sub: [{
                    name: '车辆信息',
                    path: '/car',
                }, {
                    name: '保养单',
                    path: '/maintain',
                }, {
                    name: '待提醒',
                    path: '/remind',
                }]
            }, {
                name: '信息审核',
                path: '/ismaintain',
                icon: 'el-icon-s-check',
                sub: [{
                    name: '保养模块',
                    path: '/ismaintain',
                    count: 0
                }, {
                    name: '退订订单',
                    path: '/auditChargeback',
                    count: 0
                }]
            }, {
                name: '配置管理',
                path: '/banner',
                icon: 'el-icon-help',
                sub: [{
                    name: '轮播图管理',
                    path: '/banner',
                }, {
                    name: '公告管理',
                    path: '/notice',
                }, {
                    name: '客户分类',
                    path: '/CustomerTypeList',
                }]
            }, {
                name: '日志管理',
                path: '/dailyRecord',
                icon: 'el-icon-date',
            }, {
                name: '账号管理',
                path: '/admin',
                icon: 'el-icon-user',
            }],
            timer: null,
            lu: '/ismaintain'
        }
    },
    mounted: function() {
        this.getCount()
        this.timer = setInterval(this.getCount, 1000 * 10);
    },
    watch: {
        $route() {
            this.http.post('/login/state').then(re => {
                this.$root.userInfo = re.data
            })
        }
    },
    //导航守卫
    beforeRouteEnter(to, from, next) {
        // Promise.all 會等到數組內的 Promise 都 resolve 後才會繼續跑(then)
        let _token = sessionStorage.getItem('_token');
        Promise.all([
            axios.post(window.domain + '/login/state', {
                id: sessionStorage.getItem('userId')
            }, {
                headers: {
                    'content-type': 'application/json',
                    'X-CSRF-TOKEN': _token
                }
            })
        ]).then(result => next(vm => {
            // 執行結果會按照上面順序放在 result 數組內，所以 result[0]，代表第一個函數的 resolve 結果
            if(result[0].data.code == 200) vm.$root.userInfo = result[0].data.data;
        }))
    },
    methods: {
        getCount() {
            this.http.post('/admin.statistics/news', {}, false).then(re => {
                this.$root.count = re.data;
                if(Number(re.data.count) == 0 || Number(re.data.car_care_apply) > 0) {
                    this.lu = '/ismaintain'
                } else {
                    this.lu = '/auditChargeback'
                }
                this.navList.forEach((item) => {
                    if(item.path == '/ismaintain') {
                        item.sub.forEach(subitem => {
                            if(subitem.path == '/ismaintain') {
                                subitem.count = this.$root.count.car_care_apply;
                            } else {
                                subitem.count = this.$root.count.refund;
                            }
                        })
                    }
                })
            })
        },
        handleShowEr() {
            this.dialogVisible = true;
        },
        goMsg() {
            this.$router.push({
                // path: '/ismaintain',
                path: this.lu
                // query: {
                //     is_notice: 1
                // }
            });
            // this.bus.$emit("notice");
        },
        //
        handleCollapse: function() {
            this.isCollapse = !this.isCollapse;
        },
        repwd: function() {
            this.repwdVisible = true
        },
        logOut: function() {
            this.http.post('/login/logout').then(() => {
                this.$router.push('/');
                sessionStorage.clear();
            })
        },
        repwdEnter: function() {
            this.$refs['formAuth'].validate((valid) => {
                if(valid) {
                    this.http.post('/user/changePassword', {
                        old_password: this.$md5(this.pwdForm.old_password),
                        new_password: this.$md5(this.pwdForm.new_password),
                    }).then(() => {
                        this.repwdVisible = false;
                        this.$message({
                            message: '修改成功!',
                            type: 'success'
                        });
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
        },
    },
    // 销毁定时器
    beforeDestroy: function() {
        if(this.timer) {
            clearInterval(this.timer); // 在Vue实例销毁前，清除时间定时器
        }
    },
}
</script>
